import React, { Component } from 'react';
import './App.css';
import ChordFile from "./ChordFile";
import Browser from './Browser';

import Cache from './Cache';

import ReactResizeDetector from 'react-resize-detector';
import styles from './App.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons'
import { faDropbox } from '@fortawesome/free-brands-svg-icons'
import { Dropbox } from 'dropbox';
import {LocalStorageKey, ROOT_URL} from "./Constants";
import classnames from 'classnames';
import Spinner from './Spinner';
import logo from './logo.png';

function parseQueryString(str) {
  const ret = Object.create(null);

  if (typeof str !== 'string') {
    return ret;
  }

  str = str.trim().replace(/^(\?|#|&)/, '');

  if (!str) {
    return ret;
  }

  str.split('&').forEach(function (param) {
    const parts = param.replace(/\+/g, ' ').split('=');
    // Firefox (pre 40) decodes `%3D` to `=`
    // https://github.com/sindresorhus/query-string/pull/37
    let key = parts.shift();
    let val = parts.length > 0 ? parts.join('=') : undefined;

    key = decodeURIComponent(key);

    // missing `=` should be `null`:
    // http://w3.org/TR/2012/WD-url-20120524/#collect-url-parameters
    val = val === undefined ? null : decodeURIComponent(val);

    if (ret[key] === undefined) {
      ret[key] = val;
    } else if (Array.isArray(ret[key])) {
      ret[key].push(val);
    } else {
      ret[key] = [ret[key], val];
    }
  });

  return ret;
}
// Parses the url and gets the access token if it is in the urls hash
function getAccessTokenFromUrl() {
  return parseQueryString(window.location.hash).access_token;
}

// If the user was just redirected from authenticating, the urls hash will
// contain the access token.
function isAuthenticated() {
  return !!getAccessTokenFromUrl();
}

const initialPath = localStorage.getItem(LocalStorageKey.PATH) || "";
let dropbox = null;
let storageToken = localStorage.getItem(LocalStorageKey.DROPBOX_TOKEN);

if (isAuthenticated()) {
  localStorage.setItem(LocalStorageKey.DROPBOX_TOKEN, getAccessTokenFromUrl());
  window.location = ROOT_URL;
} else if (storageToken != null) {
  dropbox = new Dropbox({ clientId: 'xjal7ulb3c4v3zs', accessToken: storageToken });
} else {
  dropbox = new Dropbox({ clientId: 'xjal7ulb3c4v3zs'});
}

const AVAILABLE_WIDTH_PADDING = 40;
const defaultFontSize = 17;

const InitialLocalStorage = {
  text: localStorage.getItem(LocalStorageKey.LAST_FILE) || '',
  fileName: localStorage.getItem(LocalStorageKey.LAST_FILE_NAME),
  transposeLevel: Cache.getTransposeLevel(localStorage.getItem(LocalStorageKey.LAST_FILE_NAME) || ''),
  fontSize: parseInt(localStorage.getItem(LocalStorageKey.FONT_SIZE) || ('' + defaultFontSize), 10)
};

class App extends Component {
  state = {
    ...InitialLocalStorage,
    showBrowser: (storageToken && InitialLocalStorage.file === ''),
    showTransposeDialog: false,
    showFontSizeDialog: false,
    isLoading: false
  };

  handleDropboxClick() {
    if (!isAuthenticated() && storageToken == null) {
      window.location = dropbox.getAuthenticationUrl(ROOT_URL);
    } else {
      this.toggleDialog({ showBrowser: true}, true);
    }
  }

  fetchFile(e) {
    const cached = Cache.getChordFile(e.content_hash);

    localStorage.setItem(LocalStorageKey.LAST_FILE_NAME, e.path_lower);

    const transposeLevel = Cache.getTransposeLevel(e.path_lower);
    this.setState({ fileName: e.path_lower, transposeLevel });

    if (cached != null) {
      localStorage.setItem(LocalStorageKey.LAST_FILE, cached);
      this.toggleDialog({ showBrowser: false, text: cached }, false);
      return;
    }

    this.setState({ showBrowser: false, isLoading: true });
    const self = this;

    dropbox.filesDownload({ path: e.path_lower })
      .then(function(response) {
        const reader = new FileReader();
        reader.onload = function() {
          const text = reader.result + '';
          localStorage.setItem(LocalStorageKey.LAST_FILE, text);
          self.toggleDialog({ isLoading: false, hasError: false, text }, false);
          Cache.saveChordFile(e.content_hash, reader.result);
        };

        reader.onerror = function() {
          self.toggleDialog({ hasError: true, isLoading: false }, false);
        };

        reader.readAsText(response.fileBlob);
      })
      .catch(function() {
        self.toggleDialog({ hasError: true, isLoading: false }, false);
        window.location = dropbox.getAuthenticationUrl(ROOT_URL);
      });

  }

  setTransposeLevel(transposeLevel) {
    this.setState({ transposeLevel });
    Cache.saveTransposeLevel(this.state.fileName, transposeLevel);
  }

  setFontSize(fontSize) {
    localStorage.setItem(LocalStorageKey.FONT_SIZE, fontSize);
    this.setState({ fontSize });
  }

  toggleDialog(newState, on) {
    this.setState(newState);

    if (on) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const { fontSize, transposeLevel, text, showBrowser, isLoading } = this.state;

    return (
      <div className={classnames(styles.app)}>
        <div className={classnames(styles.container, (showBrowser || isLoading) && styles.containerDimmed)}>
          <div className={styles.header}>
            <div className={styles.margin}/>
            <div className={styles.incButton}>
              <div className={styles.incTop}>
                <button className={classnames(styles.buttonLeft, styles.incLeft)} onClick={e => this.setTransposeLevel(transposeLevel - 1)}>
                  <FontAwesomeIcon icon={faArrowDown}/>
                </button>
                <button className={classnames(styles.buttonRight, styles.incRight)} onClick={e => this.setTransposeLevel(transposeLevel + 1)}>
                  <FontAwesomeIcon icon={faArrowUp}/>
                </button>
              </div>
              <div className={styles.incBottom}>
                Transpose: {transposeLevel}
              </div>
            </div>
            <div className={styles.margin}/>
            <button className={styles.fileButton} onClick={e => this.handleDropboxClick() }><FontAwesomeIcon icon={faDropbox}/>&nbsp;&nbsp;Open..</button>
            <div className={styles.margin}/>

            <div className={styles.incButton}>
              <div className={styles.incTop}>
                <button className={classnames(styles.buttonLeft, styles.incLeft)} onClick={e => this.setFontSize(fontSize - 1)}>
                  <FontAwesomeIcon icon={faArrowDown}/>
                </button>
                <button className={classnames(styles.buttonRight, styles.incRight)} onClick={e => this.setFontSize(fontSize + 1)}>
                  <FontAwesomeIcon icon={faArrowUp}/>
                </button>
              </div>
              <div className={styles.incBottom}>
                Font size: {fontSize}
              </div>
            </div>
          </div>
          { text === '' && (
            <div className={styles.description}>
              <img src={logo} alt="Chord Reader logo"/>
              <p>Chord Reader is a viewer for chord text files. It works well on mobile devices and supports key transposing and multi-column layout.</p>
              <p>Currently you must store your files in a Dropbox account. Click "Open.." to connect.</p>
            </div>)
          }
          <ReactResizeDetector handleWidth>
            {({ width }) => (
              <ChordFile transposeLevel={transposeLevel} text={text} fontSize={fontSize} availableWidth={width - AVAILABLE_WIDTH_PADDING}/>
            )}
          </ReactResizeDetector>
        </div>
        { (showBrowser || isLoading ) && (
          <div className={styles.overlayContainer}>
            {isLoading && <Spinner/>}
          </div>
        )}
        { <Browser isOpen={showBrowser} onClose={e => this.toggleDialog({showBrowser: false}, false)} onSelectFile={e => this.fetchFile(e)} dropbox={dropbox} initialPath={initialPath === '/' ? '' : initialPath}/> }
      </div>
    );
  }
}

export default App;
