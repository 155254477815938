import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styles from "./DialogHeader.module.css";
import CloseButton from "./CloseButton";

class DialogHeader extends Component {
  static propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func
  };

  render() {
    const { children, onClose } = this.props;
    return (
      <div className={styles.header}>
        <div className={styles.path}>
          { children }
        </div>
        <div className={styles.spacer}>
        </div>
        <CloseButton onClick={onClose}/>
      </div>
    );
  }
}


export default DialogHeader;
