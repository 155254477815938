import React, { Component} from 'react';
import PropTypes from 'prop-types';
import styles from './CloseButton.module.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

class CloseButton extends Component {
  static propTypes = {
    onClick: PropTypes.func
  };

  render() {
    const { onClick } = this.props;

    return (
      <div className={styles.closeButton}>
        <button onClick={onClick}>
          <FontAwesomeIcon size="lg" icon={faTimes}/>
        </button>
      </div>);
  }
}

export default CloseButton;
