import {isChordLine, transposeLine, linesAreChordProLines, convertChordProLinesToRegularLines} from "./Notes";

export const LineType = {
  Chord:  1,
  Word: 2,

  fromLine: line => {
    if (isChordLine(line)) {
      return LineType.Chord;

    }

    return LineType.Word;
  }
};

class TextFileLine {
  constructor(line, type) {
    this.line = line;
    this.type = type;
  }
}

export default class TextFile {
  constructor(content, maxLineWidth, fontSize, getTextWidth, transposeSteps) {
    this.content = content;
    let lines = content.split("\n");

    if (linesAreChordProLines(lines)) {
      lines = convertChordProLinesToRegularLines(lines)
    }

    const allLines = [];

    lines.forEach(line => {
      let strippedLine = TextFile.stripLine(line);
      const textFileLine = new TextFileLine();

      textFileLine.line = line;
      textFileLine.type = LineType.fromLine(line);

      if (textFileLine.type === LineType.Chord) {
        textFileLine.line = transposeLine(textFileLine.line, transposeSteps);
      }

      allLines.push(textFileLine);

      // Make double spaced paragraphs
      if (strippedLine === '') {
        allLines.push(textFileLine)
      }
    });


    this.lines = TextFile.breakLines(allLines, fontSize, maxLineWidth, getTextWidth);
  }

  static stripLine(line) {
    return line.replace(/\s/g, '');
  }

  static charIsWhiteSpace(char) {
    return char === ' ' || char === '\t';
  }

  static findBreakPoint(string, maxNumCharacters) {
    if (string.length <= maxNumCharacters) {
      return string.length
    }

    let i = maxNumCharacters;
    let end = maxNumCharacters / 2;
    while (i > end && !TextFile.charIsWhiteSpace(string[i])) {
      i--;
    }

    // Split at break point if no whitespace point was found betweeen maxNumCharacters/2..<maxNumCharacters
    if (i === end && !TextFile.charIsWhiteSpace(string[i])) {
      i = maxNumCharacters;
    }

    return i;
  }

  static breakLine(line, maxNumCharacters, output) {
    if (line.line.length <= maxNumCharacters || maxNumCharacters <= 1) {
      output.push(line);
      return;
    }

    let str = line.line;
    let i = TextFile.findBreakPoint(line.line, maxNumCharacters);
    let l1 = new TextFileLine(str.substring(0, i), line.type);

    if (TextFile.charIsWhiteSpace(str[i])) {
      i++;
    }

    let l2 = new TextFileLine(str.substring(i, str.length), line.type);

    if (TextFile.stripLine(l1.line) !== '') {
      output.push(l1)
    }

    if (l2.line.length > maxNumCharacters) {
      TextFile.breakLine(l2, maxNumCharacters, output)
    } else {
      output.push(l2)
    }
  }

  static breakChordWordPairLine(chordLine,
                                wordLine,
                                maxNumCharacters,
                                output)
  {
    maxNumCharacters = Math.max(maxNumCharacters, 1);

    if (chordLine.line.length <= maxNumCharacters && wordLine.line.length <= maxNumCharacters) {
      // Neither of the lines need breaking, just add them to output
      output.push(chordLine);
      output.push(wordLine);
    } else if (chordLine.line.count <= maxNumCharacters) {
      // Only word line needs breaking. Add chord line, then break word line into
      // N lines
      output.push(chordLine);
      this.breakLine(wordLine, maxNumCharacters, output);
  } else if (wordLine.line.length <= maxNumCharacters) {
      // Only the chord needs breaking. Break chord line into N lines,
      // put word line after first broken chord line
      let tmpOutput = [];
      TextFile.breakLine(chordLine, maxNumCharacters, tmpOutput);

      if (TextFile.stripLine(wordLine.line) !== "") {
        tmpOutput.splice(1, 0, wordLine);
        // Add empty line at end to clarify break
        tmpOutput.push(new TextFileLine("", LineType.Word))
      }

      tmpOutput.forEach(line => {
        output.push(line)
      });
    } else {
      // Both of the lines needs breaking. Begin by finding suitable break point
      // in word line
      let wordBreak = TextFile.findBreakPoint(wordLine.line, maxNumCharacters);

      let chordBreak = wordBreak;
      let chordLeadPadding = "";

      while (chordBreak >= chordLine.line.length) {
        chordLine.line += ' ';
      }

      while (chordBreak > 0 && !TextFile.charIsWhiteSpace(chordLine.line[chordBreak])) {
        chordBreak--;
        chordLeadPadding += " "
      }

      if (chordBreak === 0) {
        chordBreak = wordBreak;
        chordLeadPadding = "";
      }

      let secondChordLine = chordLine.line.substring(chordBreak, chordLine.line.length);
      let paddedWordLine = chordLeadPadding + wordLine.line.substring(wordBreak, wordLine.line.length);

      // Remove leading whitespace if it was used as breaking point
      while (secondChordLine.startsWith(" ") && paddedWordLine.startsWith(" ")) {
        secondChordLine = secondChordLine.substring(1);
        paddedWordLine = paddedWordLine.substring(1);
      }

      let c1 = new TextFileLine(chordLine.line.substring(0, chordBreak), LineType.Chord);
      let c2 = new TextFileLine(secondChordLine, LineType.Chord);
      let w1 = new TextFileLine(wordLine.line.substring(0, wordBreak), LineType.Word);
      let w2 = new TextFileLine(paddedWordLine, LineType.Word);


      if (TextFile.stripLine(c1.line) !== "") {
        output.push(c1)
      }

      if (TextFile.stripLine(w1.line) !== "") {
        output.push(w1)
      }

      TextFile.breakChordWordPairLine(c2, w2, maxNumCharacters, output)
    }
  }

  static breakLines(lines, fontSize, maxWidth, getTextWidth) {
    let testStr = "";

    while(getTextWidth(testStr) < maxWidth) {
      testStr += "A"
    }

    let maxNumCharacters = testStr.length - 1;
    let i = 0;
    const output = [];

    while (i < lines.length) {
      let line = lines[i];
      let nextLineIsWord = (i + 1 < lines.length) && lines[i+1].type === LineType.Word;

      if (line.type === LineType.Chord && nextLineIsWord) {
        this.breakChordWordPairLine(line, lines[i+1], maxNumCharacters, output);
        i+=2
      } else if (line.type === LineType.Chord) {
        this.breakLine(line, maxNumCharacters, output);
        i+=1
      } else if (line.type === LineType.Word) {
        this.breakLine(line, maxNumCharacters, output);
        i+=1
      }
    }

    return output;
  }
}

