const ChordSeparatorsRegex = /[/\\]/;
const ChordProRegex = /\[(.+?)\]/g;
const ChordProRegexTest = /\[(.+?)\]/;

const getMatches = (string, regex) => {
  let matches = [];
  let match = regex.exec(string);

  while (match ) {
    matches.push(match);
    match = regex.exec(string);
  }
  return matches;
};

const ROOTS =  ["C", "C#", "Db", "D", "D#", "Eb", "E", "F", "F#", "Gb", "G", "G#", "Ab", "A", "A#", "Bb", "B", "H"];
const ROOT_POSITIONS = {
  "C" : 0,
  "C#" : 1,
  "Db" : 1,
  "D" : 2,
  "D#" : 3,
  "Eb" : 3,
  "E" : 4,
  "F" : 5,
  "F#" : 6,
  "Gb" : 6,
  "G" : 7,
  "G#" : 8,
  "Ab" : 8,
  "A" : 9,
  "A#" : 10,
  "Bb" : 10,
  "B" : 11,
  "H" : 11
};

const TRANSPOSE_CHORDS = [
  "C", "C#", "D", "D#", "E", "F", "F#", "G", "G#", "A", "A#","B" ];

const TRANSPOSE_CHORDS_FLAT = [
  "C", "Db", "D", "Eb", "E", "F", "Gb", "G", "Ab", "A", "Bb","B" ];

const CHORD_BASE_SEQUENCES  = {
  "7": [0, 4, 7, 10],
  "7(b5)": [0, 4, 6, 10],
  "7b5": [0, 4, 6, 10],
  "7(-5)": [0, 4, 6, 10],
  "7-5": [0, 4, 6, 10],
  "7(b9)": [0, 4, 7, 10, 13],
  "7b9": [0, 4, 7, 10, 13],
  "7(-9)": [0, 4, 7, 10, 13],
  "7-9": [0, 4, 7, 10, 13],
  "-9": [0, 4, 7, 10, 13],
  "-9(#5)": [0, 4, 8, 10, 13],
  "-9#5": [0, 4, 8, 10, 13],
  "7(b9, 13)": [0, 4, 7, 10, 13, 21],
  "7(-9, 13)": [0, 4, 7, 10, 13, 21],
  "7(9, 13)": [0, 4, 7, 10, 14, 21],
  "7(#9)": [0, 4, 7, 10, 15],
  "7#9": [0, 4, 7, 10, 15],
  "7(#11)": [0, 4, 7, 10, 15, 18],
  "7#11": [0, 4, 7, 10, 15, 18],
  "7(#13)": [0, 4, 10, 21],
  "7#13": [0, 4, 10, 21],
  "9": [0, 4, 7, 10, 14],
  "9(b5)": [0, 4, 6, 10, 14],
  "9b5": [0, 4, 6, 10, 14],
  "9(-5)": [0, 4, 6, 10, 14],
  "9-5": [0, 4, 6, 10, 14],
  "11": [0, 4, 7, 10, 14, 17],
  "13": [0, 4, 7, 10, 14, 17, 21],
  "m": [0, 3, 7],
  "m6": [0, 3, 7, 9],
  "m6(9)": [0, 3, 7, 9, 14],
  "m69": [0, 3, 7, 9, 14],
  "mM7": [0, 3, 7, 11],
  "m7(b5)": [0, 3, 6, 10],
  "m7(-5)": [0, 3, 6, 10],
  "m7-5": [0, 3, 6, 10],
  "m7(9)": [0, 3, 7, 10, 14],
  "m79": [0, 3, 7, 10, 14],
  "m9": [0, 3, 7, 10, 14],
  "m7(9, 11)": [0, 3, 7, 10, 14, 17],
  "m11": [0, 3, 7, 10, 14, 17],
  "m13": [0, 3, 7, 10, 14, 17, 21],
  "dim": [0, 3, 6],
  "dim7": [0, 3, 6, 9],
  "aug": [0, 4, 8],
  "aug7": [0, 4, 8, 10],
  "augM7": [0, 4, 8, 11],
  "aug9": [0, 4, 8, 10, 14],
  "7sus4": [0, 5, 7, 10],
  "add2": [0, 2, 4, 7],
  "add4": [0, 4, 5, 7],
  "add9": [0, 4, 7, 14],
  "madd9": [0, 3, 7, 14],
  "5": [0, 7],
  "-5": [0, 4, 6],
  "": [0, 4, 7],
  "6": [0, 4, 7, 9],
  "69": [0, 4, 7, 9, 14],
  "6(9)": [0, 4, 7, 9, 14],
  "M7": [0, 4, 7, 11],
  "M7(9)": [0, 4, 7, 11, 14],
  "M79": [0, 4, 7, 11, 14],
  "M9": [0, 4, 7, 11, 14],
  "M11": [0, 4, 7, 11, 14, 17],
  "M13": [0, 4, 7, 11, 14, 17, 21],
  "maj7": [0, 4, 7, 11],
  "maj7b5": [0, 4, 6, 11],
  "maj7#11": [0, 4, 7, 11, 18],
  "maj9": [0, 4, 7, 11, 14],
  "maj9b5": [0, 4, 6, 11, 14],
  "maj7 add 13": [0, 4, 7, 11, 21],
  "maj7 add 13 add 9": [0, 4, 7, 11, 14, 21],
  "sus2": [0, 2, 7],
  "sus4": [0, 5, 7],
  "m add Maj7": [0, 3, 7, 11],
  "m6 add Maj7": [0, 3, 7, 9, 11],
  "m7": [0, 3, 7, 10],
  "m7b5": [0, 3, 6, 10],
  "m7b6": [0, 3, 7, 8, 10],
  "m7 add 11": [0, 3, 7, 10, 17],
  "m7b5 add 11": [0, 3, 6, 10, 17],
  "m7 add 13": [0, 3, 7, 10, 21],
  "m9b5": [0, 3, 6, 10, 14],
  "m9 add Maj7": [0, 3, 7, 11, 14],
  "7#5": [0, 4, 8, 10],
  "7b5b9": [0, 4, 6, 10, 13],
  "7#5#9": [0, 4, 8, 10, 15],
  "7b5#9": [0, 4, 6, 10, 15],
  "7#5b9": [0, 4, 8, 10, 13],
  "9#5": [0, 4, 8, 10, 14],
  "13b5": [0, 4, 6, 10, 14, 21],
  "13b9": [0, 4, 7, 10, 13, 21],
  "Dim": [0, 3, 6],
  "Dim 7th": [0, 3, 6, 9],
  "Aug": [0, 4, 8],
  "Aug 7th": [0, 4, 8, 10],
  "Aug 9th": [0, 4, 8, 10, 14]
};


const allChords = (() => {
  let ret = {};

  ROOTS.forEach(root => {
    Object.keys(CHORD_BASE_SEQUENCES).forEach(variation => {
      ret[root+variation] = root;
    });
  });

  return ret;
})();

export const transposeNote = (note, steps) => {
  if (steps === 0) {
    return note;
  }

  if (ROOT_POSITIONS.hasOwnProperty(note)) {
    const rootIndex = ROOT_POSITIONS[note];
    let transposedIndex = rootIndex + steps;

    // Need to handle negative values,
    // so module operator does not work
    while (transposedIndex >= 12) {
      transposedIndex -= 12;
    }

    while (transposedIndex < 0) {
      transposedIndex += 12;
    }

    if (steps > 0) {
      return TRANSPOSE_CHORDS[transposedIndex];
    } else {
      return TRANSPOSE_CHORDS_FLAT[transposedIndex];
    }
  } else {
    return note;
  }
};

export const getRootFromChord = (chord) => {
  let matchLen = 0;
  let chordRoot = null;
  let components = chord.split(ChordSeparatorsRegex);
  let rootPart = components[0];

  ROOTS.forEach(root => {
    if (rootPart.indexOf(root) === 0 && root.length > matchLen) {
      chordRoot = root;
      matchLen = root.length;
    }
  });


  return chordRoot
};

const splitChord = (chord) => {
  const root = getRootFromChord(chord);
  if (!root) {
    return null;
  }

  const chordType = chord.substr(root.length);
  let components = chordType.split(ChordSeparatorsRegex);
  return [root, ...components];
};

export const transposeChord = (chord, steps) => {
  let parts = splitChord(chord);
  if (!parts || parts.length < 2) {
    return chord;
  }

  let ret = transposeNote(parts[0], steps);
  ret += parts[1];

  if (parts.length === 3) {
    let transposedRootNote = transposeNote(parts[2], steps);
    ret += "/" + transposedRootNote;
  }

  if (ret.endsWith("|")) {
    ret = ret.substr(0, ret.length - 1);
  }

  return ret;
};

const isStringWhitespace = (str) => {
  return str.match(/^\s*$/);
};

export const transposeLine = (line, steps) => {
  let eatingChordChars = false;
  let curChord = "";
  let numWhiteSpaceToSkip = 0
  let ret = "";

  let cleanLine = line.replace("|", " ").replace("*", " ");
  const lineArray = Array.from(cleanLine);
  lineArray.forEach(substring => {
    const isWhitespace = isStringWhitespace(substring);

    if (eatingChordChars) {
      if (isWhitespace) {
        eatingChordChars = false;
        let transposedChord = transposeChord(curChord, steps);
        let sizeDiff = transposedChord.length - curChord.length;

        if (ret.length > 0 && !ret.endsWith(" ")) {
          ret += " ";
        }

        ret += transposedChord;

        if (sizeDiff > 0) {
          numWhiteSpaceToSkip = sizeDiff - 1;
        } else if (sizeDiff < 0) {
          for (let i = 0; i < -sizeDiff; i++) {
            ret += " ";
          }
        } else {
          ret += substring;
        }

        curChord = "";
      } else {
        curChord += substring;
      }
    } else {
      if (isWhitespace) {
        if (numWhiteSpaceToSkip > 0) {
          numWhiteSpaceToSkip -= 1;
        } else {
          ret += substring;
        }
      } else {
        eatingChordChars = true;
        curChord = "" + substring;
      }
    }
  });

  if (eatingChordChars) {
    if (ret.length > 0 && !ret.endsWith(" ")) {
      ret += " ";
    }

    ret += transposeChord(curChord, steps)
  }

  return ret;
};

const splitWords = (str) => {
  return str.split(/\s+/);
};

export const isChordLine = (line) => {
  let words = splitWords(line);

  let numChords = 0;
  let numWords = 0;

  words.forEach(word => {
    if (word !== "") {
      let components = word.split(ChordSeparatorsRegex);
      let chordPart = components[0];

      if (chordPart.startsWith("|")) {
        chordPart = chordPart.substr(1);
      }

      if (chordPart.endsWith("|")) {
        chordPart = chordPart.substr(0, chordPart.length - 1);
      }

      if (allChords.hasOwnProperty(chordPart)) {
        numChords += 1;
      } else if (word.length > 0) {
        if (word.startsWith("[") && word.endsWith("]"))
        {
          // Bracketed word, i.e. [CHORUS],
          // don't count these as words
        } else if (/^\d+$/.test(word))
        {
          // A number, don't count that as word
          // either
        } else {
          numWords += 1
        }
      }
    }
  });

  return numChords > 0 && numChords >= numWords
};

export const linesAreChordProLines = (lines) => {
  let numChordProLines = 0;
  let numNonChordProLines = 0;

  lines.forEach(line => {
    if (line !== "") {
      if (ChordProRegexTest.test(line)) {
        numChordProLines += 1
      } else {
        numNonChordProLines += 1
      }
    }
  });

  return numChordProLines >= 1 && numChordProLines > numNonChordProLines
};

export const convertChordProLinesToRegularLines = (lines) => {
  const retLines = [];

  for (let i = 0; i < lines.length; i++) {
    let line = lines[i];
    let lyricLine = line.split("");
    let chordLine = [];

      // Skip directives
    if (line.startsWith("{") && line.endsWith("}")) {
      continue;
    }

    while (chordLine.length < lyricLine.length) {
      chordLine.push(" ");
    }

    const matches = getMatches(line, ChordProRegex);

    let matchOffset = 0;
    for (let match of matches) {
      const matchText = match[0];
      let trimmedMatchText = match[1];

      chordLine.splice(match.index - matchOffset, matchText.length);
      lyricLine.splice(match.index - matchOffset, matchText.length);


      for (let j = 0; j < trimmedMatchText.length; j++) {
        chordLine[match.index + j - matchOffset] = trimmedMatchText[j];
      }

      matchOffset += matchText.length;
    }

    chordLine = chordLine.join("");
    lyricLine = lyricLine.join("");

    while (chordLine.endsWith(' ')) {
      chordLine = chordLine.substr(0, chordLine.length - 1);
    }

    if (lyricLine !== line) {
      retLines.push(chordLine);
      retLines.push(lyricLine);
    } else {
      retLines.push(line);
    }
  }

  return retLines;
};


