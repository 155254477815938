import React, { Component } from 'react';
import styles from './Spinner.module.css';
import classNames from 'classnames';
import PropTypes from 'prop-types';

class Spinner extends Component {
  static propTypes = {
    dark: PropTypes.bool
  };

  static defaultProps = {
    dark: false
  };

  render() {
    const { dark } = this.props;

    return (
      <div className={classNames(styles["lds-spinner"], dark && styles["lds-spinner-dark"])}>
        <div/>
        <div/>
        <div/>
        <div/>
        <div/>
        <div/>
        <div/>
        <div/>
        <div/>
        <div/>
        <div/>
        <div/>
      </div>);
  }
}

export default Spinner;
