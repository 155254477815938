const PathPrefix = 'pathCache-';
const TransposePrefix = 'transpose-';

class Cache {
  static saveDirectoryResult(path, result){
    localStorage.setItem(PathPrefix + path, JSON.stringify(result));
  }

  static getCachedDirectory(path) {
    const res = localStorage.getItem(PathPrefix + path);
    if (res != null) {
      return JSON.parse(res);
    }

    return null;
  }

  static saveChordFile(hash, file) {
    localStorage.setItem(hash, file);
  }

  static getChordFile(hash) {
    return localStorage.getItem(hash);
  }

  static saveTransposeLevel(path, transposeLevel) {
    localStorage.setItem(TransposePrefix + path, transposeLevel + '');
  }

  static getTransposeLevel(path) {
    return parseInt(localStorage.getItem(TransposePrefix + path) || '0', 10);
  }
}

export default Cache;
