let rootUrl = 'https://www.chordreader.com';

if (process.env.NODE_ENV === 'development') {
  rootUrl = 'http://localhost:3000';
}

export const ROOT_URL = rootUrl;

export const LocalStorageKey = Object.freeze({
  LAST_FILE_NAME: "file-name",
  LAST_FILE: "file",
  FONT_SIZE: "font-size",
  PATH: 'current-path',
  DROPBOX_TOKEN: 'dropbox-token'
});
