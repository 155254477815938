import React, {Component, Fragment} from 'react';
import styles from './Browser.module.css';
import PropTypes from 'prop-types';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faFileAlt, faFolder } from '@fortawesome/free-regular-svg-icons'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import _ from 'lodash';
import loader from './loader.gif';
import {LocalStorageKey, ROOT_URL} from "./Constants";
import Cache from './Cache';
import DialogHeader from "./DialogHeader";
import classnames from 'classnames';

class Browser extends Component {
  static propTypes = {
    initialPath: PropTypes.string,
    dropbox: PropTypes.object,
    onClose: PropTypes.func,
    onSelectFile: PropTypes.func,
    isOpen: PropTypes.bool
  };

  _curPath = null;

  loadFiles(path, dropbox, loadCached) {
    const self = this;

    if (loadCached) {
      const cached = Cache.getCachedDirectory(path);
      if (cached != null) {
        self.setState({entries: cached, isLoading: false});
      }
    }

    this._curPath = path;
    const requestedPath = path;

    dropbox.filesListFolder({path, limit: 2000})
      .then(function(response) {
        if (self._curPath === requestedPath) {
          self.setState({entries: response.entries, isLoading: false});
        }

        Cache.saveDirectoryResult(path, response.entries);
      })
      .catch(function(error) {
        console.log(error);
        if (self._curPath === requestedPath) {
          self.setState({ hasError: false, isLoading: false });
        }

        window.location = dropbox.getAuthenticationUrl(ROOT_URL);
      });
  }

  componentDidUpdate(oldProps) {
    if (!oldProps.isOpen && this.props.isOpen && this.state.entries.length === 0) {
      let path = this.props.initialPath;

      const cached = Cache.getCachedDirectory(path);

      if (cached != null) {
        this.setState({
          entries: cached,
          isLoading: false,
          hasError: false,
          path
        });
      } else {
        this.setState({
          entries: [],
          isLoading: true,
          hasError: false,
          path
        });
      }

      this.loadFiles(path, this.props.dropbox, false);
    }
  }

  constructor(props) {
    super(props);


    this.state = {
      entries: [],
      isLoading: false,
      hasError: false,
      path: props.initialPath
    };

    if (props.isOpen) {
      this.state =  { ...this.state, isLoading: true };
      this.loadFiles(props.initialPath, props.dropbox, false);
    }
  }

  selectPath(entry) {
    this.setState({ entries: [], isLoading: true, path: entry.path_display});
    localStorage.setItem(LocalStorageKey.PATH, entry.path_display);
    this.loadFiles(entry.path_display, this.props.dropbox, true);
  }

  selectPathComponent(index) {
    const { path } = this.state;
    let pathComponents = path.split('/');
    const partialPath = pathComponents.slice(0, index+1).join('/');
    this.selectPath({ name: partialPath, path_display: partialPath});
  }


  render() {
    const { onClose, onSelectFile, isOpen } = this.props;
    const { path, isLoading, entries } = this.state;
    const folders = _.sortBy(entries.filter(x => x['.tag'] === 'folder'), ['name']);
    const files = _.sortBy(entries.filter(x => x['.tag'] === 'file'), ['name']).filter(x => x.name.endsWith('.txt') || x.name.endsWith('crd'));

    let pathComponents = path.split('/');
    pathComponents[0] = 'Root';

    return (
      <div className={classnames(styles.browserContainer, isOpen && styles.open)}>
        <div className={styles.browser}>
          <DialogHeader onClose={onClose}>
            {pathComponents.map((pathComponent, idx) => (
              <Fragment key={idx}>
                <div className={classnames(styles.pathComponent, idx === pathComponents.length - 1 && styles.activePathComponent)} onClick={e => this.selectPathComponent(idx)}>{pathComponent}</div>
                { idx < pathComponents.length - 1 && <FontAwesomeIcon icon={faChevronRight} size="xs"/> }
              </Fragment>
            ))}
          </DialogHeader>
          <div className={styles.files}>
            {isLoading && <img alt="Loading.." src={loader}/>}
            { folders.concat(files).map(entry => (
              <div key={entry.name} onClick={e => entry['.tag'] === 'folder' ? this.selectPath(entry) : onSelectFile(entry)} className={styles.entry}>
                {entry['.tag'] === 'folder' && <FontAwesomeIcon icon={faFolder}/>}
                {entry['.tag'] === 'file' && <FontAwesomeIcon icon={faFileAlt}/>}
                {entry.name}
              </div>
            ))
            }
          </div>
        </div>
      </div>
    );
  }
}

export default Browser;
