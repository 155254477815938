import React, {Component} from 'react';
import styles from './ChordFile.module.css';
import TextFile, { LineType } from './Model/TextFile';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const ColumnMargin = 60;

class ChordFile extends Component {
  static propTypes = {
    text: PropTypes.string,
    transposeLevel: PropTypes.number,
    availableWidth: PropTypes.number,
    fontSize: PropTypes.number
  };

  render() {
    const { text, transposeLevel, availableWidth, fontSize } = this.props;

    if (!availableWidth) {
      return <span/>;
    }

    const isLandscape = window.innerWidth > window.innerHeight;
    const useTwoColumns = isLandscape && availableWidth >= 1000 && fontSize < 26;

    const getTextWidth = (text) => {
      const test = document.getElementById("sizeTest");
      test.innerText = text;
      test.style.fontSize = fontSize + 'px';
      return test.clientWidth + 20;
    };


    const t = new TextFile(text, useTwoColumns ? (availableWidth / 2 - ColumnMargin): availableWidth, fontSize, getTextWidth, transposeLevel);
    let i = 0;
    const pairedLines = [];

    while (i < t.lines.length) {
      if (t.lines[i].type === LineType.Chord) {
        if (i + 1 < t.lines.length && t.lines[i+1].type === LineType.Word) {
          pairedLines.push( { pair: true, lines: [t.lines[i], t.lines[i+1]] });
          i++;
        } else {
          pairedLines.push( { pair: false, lines: [t.lines[i]]});
        }
      } else {
        pairedLines.push( { pair: false, lines: [t.lines[i]]});
      }
      i++;
    }

    let style = {
      fontSize: fontSize + 'px',
      maxWidth: availableWidth + 'px'
    };

    if (useTwoColumns) {
      style.columnGap = ColumnMargin + 'px';
      style.columnCount = 2;
    }

    return (
      <div className={classNames(styles.fileContainer)} style={style}>
        { pairedLines.map( (pairedLine, idx) => (
          <div key={idx} className={classNames(pairedLine.pair && styles.pairedLine)}>
            { pairedLine.lines.map((line, pairIdx) => (
              <span key={pairIdx} className={line.type === LineType.Chord ? styles.chord : styles.word}>
                { line.line + "\n" }
                </span>
            )) }
          </div>
        )) }
      </div>
    );
  }
}


export default ChordFile;
